import '../styles/style.scss';

BX.ready(function()
{
	document.querySelector('.landing-block--redsign-menu01-2 .burger').addEventListener('click',function(){
		this.classList.toggle('active');
		var block = document.querySelector('.landing-block--redsign-menu01-2');
		block.classList.toggle('menu-in');
		document.querySelector('body').classList.toggle('menu-hidden');
	})
});